<template>
  <b-field
    :class="'date-picker--field' + (focused ? ' focused' : '')"
    tabindex="0"
    :aria-label="ariaLabel"
    :label="label"
    :style="{ display: 'inline-table', textAlign: 'left' }"
    :type="fieldType"
    :message="errorMessage"
  >
    <b-datepicker v-model="selected" v-if="fields.menu">
      <template v-slot:trigger>
        <b-button
          icon-left="calendar-today"
          type="is-ghost"
          aria-hidden="true"
          aria-label="visual presentation button"
          :style="{
            border: 0,
            borderRadius: '6px'
          }"
          @focus="onFocus"
          @blur="onBlur"
        />
      </template>
    </b-datepicker>

    <b-field :style="styles.monthField" v-if="fields.month">
      <b-select
        month
        class="date-picker--select"
        placeholder="Month"
        :aria-label="ariaPrefix + ' month'"
        ref="month"
        v-model="month"
        @focus="onFocus"
        @blur="onBlur"
      >
        <option v-for="month in months" :value="month" :key="month">
          {{ month }}
        </option>
      </b-select>
    </b-field>

    <b-field :style="styles.dayField" v-if="fields.dayOfMonth">
      <b-input
        v-model="day"
        day
        class="date-picker--input day"
        placeholder="Day"
        ref="day"
        maxlength="2"
        :aria-label="ariaPrefix + ' day'"
        :has-counter="false"
        @focus="onFocus"
        @blur="onBlur"
      ></b-input>
    </b-field>

    <b-field :style="styles.yearField" v-if="fields.year">
      <b-input
        year
        class="date-picker--input year"
        v-model="year"
        placeholder="Year"
        :aria-label="ariaPrefix + ' year'"
        ref="year"
        @focus="onFocus"
        @blur="onBlur"
      ></b-input>
    </b-field>

    <!-- </div> -->
  </b-field>
</template>

<script>
/*
  Example:

    import DatePicker from '@/components/inputs/DatePicker'

    <DatePicker
      ref="datePickerComponent"

      ariaLabel="filter from date"
      label="filter from date"

      ariaPrefix="from"

      style="padding-right: 10px"

      :onUpdate="onUpdate"
    />

    const { day, month, year, allValid, utcFormat } = this.$refs.datePickerComponent.getValue ();

    // this.$refs.datePickerComponent.setValue ({
    //  day: 2,
    //  month: 'January',
    //  year: 2041
    // });

    this.$refs.datePickerComponent.setValue ();
*/
import moment from 'moment'
//
import { data } from './keys/data'
import { methods } from './keys/methods'
//

export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    ariaLabel: {
      type: String,
      default: 'date picker'
    },
    ariaPrefix: {
      type: String,
      default: ''
    },
    onUpdate: {
      type: Function,
      default: () => {}
    },
    value: {
      type: String,
      default: ''
    },
    errorMessage: {
      type: String,
      default: ''
    },
    startEmpty: {
      type: Boolean,
      default: false
    },
    fields: {
      type: Object,
      default() {
        return {
          menu: true,
          year: true,
          month: true,
          dayOfMonth: true
        }
      }
    }
  },
  data,

  watch: {
    selected: function(value) {
      var dayOfMonth = moment(value).date()
      var month = moment(value).format('MMMM')
      var numericMonth = moment(value).format('MM')
      var year = moment(value).format('YYYY')

      this.day = dayOfMonth
      this.month = month
      this.numericMonth = numericMonth
      this.year = year
    },
    value: function(value) {
      this.setValue(value)
      this.$emit('update:errorMessage', '')
    },
    day() {
      this.updateValue()
    },
    month() {
      this.updateValue()
    },
    numericMonth() {
      this.updateValue()
    },
    year() {
      this.updateValue()
    }
  },
  computed: {
    fieldType() {
      const errorMessage = this.errorMessage

      if (typeof errorMessage === 'string' && errorMessage.length >= 1) {
        return 'is-danger'
      }

      return ''
    },
    days: function() {
      var days = []
      for (let a = 1; a <= 31; a++) {
        days.push(a)
      }

      return days
    }
  },
  methods,
  beforeMount() {
    this.setValue(this.value)
  }
}
</script>

<style lang="scss">
@import '~@/assets/styles/_variables';

.date-picker--field {
  &:focus {
    border-radius: 4px;
  }

  &.focused > .field-body {
    box-shadow: 0 4px 14px -5px rgba(0, 0, 0, 0.18), $tabable-focus;
  }

  & > .field-body {
    box-shadow: 0 4px 14px -5px rgba(0, 0, 0, 0.18);
    border-radius: 6px;
  }
}

.date-picker--select,
.date-picker--input {
  select {
    border-radius: 0 !important;
    border: 0 !important;
    box-shadow: none !important;
    padding-right: 0 !important;
  }
  input {
    border-radius: 0 !important;
    border: 0 !important;
    box-shadow: none !important;
  }
}

.date-picker--input.year {
  input {
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
  }
}
</style>

<style lang="scss" scoped>
@import '~@/assets/styles/_variables';

@media screen and (min-width: $tablet) {
  .button {
    height: 40px;
    width: 40px;
  }
}
</style>
