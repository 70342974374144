export function data() {
  var field = {
    width: '70px',
    display: 'inline-block',
    textAlign: 'left'
  }

  return {
    focused: false,
    selected: new Date(),
    day: '',
    month: null,
    year: '',
    months: [
      '',
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ],
    styles: {
      monthField: Object.assign({}, field, {
        width: '127px'
      }),
      dayField: Object.assign({}, field, {
        width: '60px'
      }),
      yearField: field
    }
  }
}
