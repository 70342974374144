import moment from 'moment'
import { notifyError } from '@/services/notify'

/*
	
*/
export const methods = {
  onFocus: function() {
    this.focused = true
  },
  onBlur: function() {
    this.focused = false

    const testValidityDate = `${this.year}-${this.numericMonth}-${this.day}T05:00:00Z`

    if (!this.isValidDate(testValidityDate) && !this.startEmpty) {
      notifyError(
        `The date, ${this.month}-${this.day}-${this.year}, is an invalid date. Please select, or enter, a valid date.`
      )
      return
    }

    this.onUpdate({
      day: this.day,
      month: this.month,
      numericMonth: this.numericMonth,
      year: this.year
    })
  },
  setValue2: function({ dayOfMonth, month, year }) {
    this.day = dayOfMonth
    this.month = month
    // this.numericMonth = numericMonth
    this.year = year
  },
  setValue: function(dateString) {
    try {
      if (typeof dateString !== 'string' || dateString.length === 0) {
        this.day = null
        this.month = null
        this.numericMonth = null
        this.year = null
        console.debug('dateString needs to be a string of length >= 1', { dateString })

        if (this.startEmpty === false) {
          const today = moment()
          this.day = today.date().toString()
          this.month = today.format('MMMM')
          this.numericMonth = today.format('MM')
          this.year = today.year().toString()

          //2022-07-08T04:00:00Z
          dateString = `${this.year}-${today.format('MM')}-${today.format('DD')}T12:00:00Z`
        }
      }

      console.debug('dateString = ' + dateString)

      const m = moment(dateString, true)

      if (!m.isValid()) {
        console.debug(`The date string value provided "${dateString}" was not parseable.`)
        return
      }

      const year = m.year().toString()
      const month = m.format('MMMM')
      const numericMonth = m.format('MM')
      const date = m.date().toString()

      if (typeof year === 'string' && typeof month === 'string' && typeof date === 'string') {
        this.day = date
        this.month = month
        this.numericMonth = numericMonth
        this.year = year
      }
    } catch (exception) {
      console.debug(exception)
    }
  },
  updateValue: function() {
    const { utcFormat } = this.getValue()
    if (typeof utcFormat === 'string') {
      this.$emit('input', utcFormat)
    }
  },

  isValidDate(dateStr) {
    try {
      //2023-01-05T05:00:00Z
      var ds = dateStr.split('T')
      var dszero = ds[0]
      var s = dszero.split('-')

      console.debug('year s0=' + s[0])
      console.debug('month s1=' + s[1])
      console.debug('day s2=' + s[2])

      var d = new Date(+s[0], s[1] - 1, +s[2])
      if (Object.prototype.toString.call(d) === '[object Date]') {
        if (!isNaN(d.getTime()) && d.getDate() == s[2] && d.getMonth() == s[1] - 1) {
          return true
        }
      }
      return false
    } catch (x) {
      return false
    }
  },

  getValue: function() {
    const day = this.day
    const month = this.month
    const numericMonth = this.numericMonth
    let year = this.year

    let utcExtended = ''
    try {
      let _year = parseInt(year)

      if (typeof _year === 'number' && _year >= 1) {
        if (year.length === 1) {
          year = '000' + year
        } else if (year.length === 2) {
          year = '00' + year
        } else if (year.length === 3) {
          year = '0' + year
        } else if (year.length >= 5) {
          const date = moment(`${month} ${day}, 2000`, 'LL', true)
          const utcFormat = date.utc().format()

          const advanced = utcFormat.split('-')
          advanced[0] = year

          const combined = advanced.join('-')
          utcExtended = combined
        }
      }
    } catch (x) {
      console.error(x)
    }

    const LL = `${month} ${day}, ${year}`
    const date = moment(LL, 'LL', true)
    const allValid = date.isValid()

    let utcFormat = null
    if (allValid) {
      utcFormat = date.utc().format()
      utcExtended = utcFormat
    }

    let dayString = ''

    try {
      if (day !== null) {
        dayString = day.toString()
        if (dayString.length === 1) {
          dayString = '0' + dayString
        }
      }
    } catch (x) {
      console.error(x)
    }

    let extendedValid = false
    if (utcExtended.length >= '10') {
      extendedValid = true
    }

    return {
      utcExtended,
      utcFormat,
      allValid,
      extendedValid,
      day,
      dayString,
      month,
      numericMonth,
      year
    }
  }
}
